import React, {useState, useEffect} from 'react';
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import axios from 'axios/index'
import './App.css';

const API_HOST_ENDPOINT = process.env.REACT_APP_ENDPOINT || "<enter-endpoint>";
const API_GW_ID = API_HOST_ENDPOINT.match(/(https:\/\/)(.+)(?=\.execute-api)/)[2];
const VPCE_BASE_ENDPOINT = `https://${API_GW_ID}-vpce-014749df1ba215267.execute-api.ap-southeast-2.amazonaws.com/prod`;

class App extends React.Component {

  state = {
    location: '',
    accuracy: '',
    uuid: '',
    phNumber: '',
    waits: 0,
    requestSent: false
  };

  constructor(props) {
    super(props);
    
    this.phInput = React.createRef();
  }

  handlePhChange = (event) => {
    this.setState({phNumber: event.target.value});
  };

  buttonPressed = async () => {
    const phNumber = this.phInput.current.value; // this.state.phNumber;
    
    this.requestLocation('+61' + phNumber);
  }

  backoffTime = (waits) => {
    if (waits < 60) {
      return 3;
    }

    if (waits < 60 * 5) {
      return 5;
    }

    if (waits < 60 * 10) {
      return 10;
    }

    if (waits < 60 * 15) {
      return 15;
    }

    return -1;
  }

  requestLocation = async (phNumber) => {
    phNumber = phNumber.replace(/\s/g, '');
    try {
        await this.setState({ requesting: true });
        const response = await axios.get(`${VPCE_BASE_ENDPOINT}/request`, {
          params: {
            phNumber: phNumber
          }
        });
        await this.setState({ resetting: false, waiting: true, uuid: response.data.uuid, requestSent: true });

        console.log('UUID: ' + this.state.uuid);

        this.timer = setInterval(async () => {
          const waits = this.state.waits;
          const backoff = this.backoffTime(waits);
          if (backoff === -1) {
            clearInterval(this.timer);
          }

          const skip = waits % backoff !== 0;

          console.log(`Waits: ${waits}, backoff: ${backoff}, skip: ${skip}`);

          await this.setState({ waits: waits + 1 });

          if (skip) {
            return;
          }

          console.log('UUID now: ' + this.state.uuid);
          const response = await axios.get(`${VPCE_BASE_ENDPOINT}/check`, {
            params: {
              uuid: this.state.uuid
            }
          });

          if (!response.data.populated) {
            return;
          }

          this.setState({
            location: response.data.location,
            accuracy: response.data.accuracy,
            loading: false
          });

          clearInterval(this.timer);
        }, 1000);
    } catch (e) {
        console.log("ERROR", e);
    }
};
  
  LoadingButton = () => {
    const [isLoading, setLoading] = useState(false);
  
    useEffect(() => {
      if (isLoading) {
        this.buttonPressed();
      }
    }, [isLoading]);
  
    const handleClick = () => setLoading(true);
  
    return (
      <Button
        variant="primary"
        disabled={isLoading}
        onClick={!isLoading ? handleClick : null}
      >
        {isLoading ? 'Loading…' : 'Request location'}
      </Button>
    );
  }

  render = () => {
    const url = `https://maps.google.com/maps?q=${this.state.location}`;
    const embedUrl = `https://maps.google.com/maps?q=${this.state.location}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
    return (
      <Container className="p-3">
        <Jumbotron>
          Enter the mobile number as format +61 411 111 111 (exclude +61).
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">+61</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Phone number"
              aria-label="Phone number"
              aria-describedby="basic-addon1"
              onChange={this.handlePhChange}
              ref={this.phInput}
            />
          </InputGroup>
          {!this.state.requestSent && 
            <this.LoadingButton></this.LoadingButton>
          }
          {this.state.requestSent && this.state.location === '' && 
            <>
              <div>Request sent, awaiting location...</div>
              <div>Do not close this window, map will display automatically</div>
            </>
          }
          {this.state.location && 
            
            <div>
              <div>Location: {this.state.location} (Accurate to {this.state.accuracy} metres)</div>
              <div>Google maps: <a href={url} target='_blank' rel="noopener noreferrer">{url}</a></div>
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe width="600" height="500" title="gmaps"
                          id="gmap_canvas" src={embedUrl} frameborder="0" 
                          scrolling="no" marginheight="0" marginwidth="0">
                  </iframe>
                </div>
              </div>
            </div>
            
          }
        </Jumbotron>
      </Container>
    );
  }
}

export default App;
